<template>
  <!-- 三清宝页面 -->
  <el-row class="warp">
    <el-col :span="24" class="warp-main">
      <!--工具条-->
      <el-form :inline="true" size="small">
        <el-form-item>
          <el-button type="primary" @click="showAddDialog">新增</el-button>
        </el-form-item>
      </el-form>
      <!--列表-->
      <el-table :data="listData">
        <el-table-column type="index" label="序号" width="60" />
        <el-table-column prop="terminal" label="终端" :formatter="formatTerminal" />
        <el-table-column prop="startVer" label="开始版本" width="120" />
        <el-table-column prop="endVer" label="结束版本" width="120" />
        <el-table-column prop="targetVer" label="目标版本" width="120" />
        <el-table-column prop="content" label="升级文案" :show-overflow-tooltip="true" />
        <!-- <el-table-column prop="link" label="链接" /> -->
        <el-table-column prop="enforceFlag" label="是否强制更新" :formatter="formatEnforceFlag" width="120" />
        <el-table-column prop="state" label="状态" :formatter="formatState" />
        <el-table-column prop="createUser" label="创建人" width="160" />
        <el-table-column prop="createTime" label="创建时间" width="160" />
        <el-table-column label="操作" fixed="right" width="160">
          <template slot-scope="scope">
            <el-button size="small" @click="showEditDialog(scope.row)">编辑</el-button>
            <el-button v-if="scope.row.state === 1" size="small" type="danger" @click="updateState(scope.row.versionId, 0)">禁用</el-button>
            <el-button v-else size="small" type="primary" @click="updateState(scope.row.versionId, 1)">启用</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="size"
        :page-sizes="[10, 20, 30, 50]"
        :total="total"
        style="float:right;"
      >
      </el-pagination>
      <!-- 新增编辑弹框 -->
      <el-dialog :title="addEditTitle" :visible.sync="addEditDialog" :close-on-click-modal="false" width="600px">
        <el-form :model="addEditForm" :rules="addEditFormRules" ref="addEditForm" size="small" label-width="120px">
          <el-form-item label="目标版本" prop="targetVer">
            <el-input v-model.trim="addEditForm.targetVer" placeholder="请输入目标版本号" />
          </el-form-item>
          <el-form-item label="客户端" prop="terminal">
            <el-radio-group v-model="addEditForm.terminal" @change="changeTerminal">
              <el-radio v-for="item in terminalOptions" :key="item.value" :label="item.value">{{ item.label }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="showUploadFile" label="上传更新APK包" prop="link">
            <el-row type="flex">
              <el-col :span="16">
                <el-input v-model.trim="addEditForm.link" disabled />
              </el-col>
              <el-col :span="8">
                <el-upload action="" :http-request="uploadFile" :show-file-list="false" style="margin-left:10px">
                  <el-button type="primary">上传更新APK包</el-button>
                </el-upload>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="开始版本" prop="startVer">
            <el-select v-model.trim="addEditForm.startVer" placeholder="请选择开始版本号">
              <el-option v-for="item in versionOptions" :key="item.versionId" :label="item.targetVer" :value="item.targetVer"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="结束版本" prop="endVer">
            <el-select v-model.trim="addEditForm.endVer" placeholder="请选择结束版本号">
              <el-option v-for="item in versionOptions" :key="item.versionId" :label="item.targetVer" :value="item.targetVer"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="升级文案" prop="content">
            <el-input type="textarea" v-model="addEditForm.content" maxlength="300" show-word-limit :autosize="{ minRows: 3, maxRows: 5 }" placeholder="请输入升级文案"></el-input>
          </el-form-item>
          <el-form-item label="是否强更" prop="enforceFlag">
            <el-switch v-model.trim="addEditForm.enforceFlag"></el-switch>
          </el-form-item>
          <el-form-item label="状态" prop="state">
            <el-switch v-model.trim="addEditForm.state"></el-switch>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="addEditDialog = false" size="small">取消</el-button>
          <el-button type="primary" v-if="isAddOrEdit === 1" @click.native="addSubmit" size="small">保存</el-button>
          <el-button type="primary" v-else @click.native="editSubmit" size="small">保存</el-button>
        </div>
      </el-dialog>
    </el-col>
  </el-row>
</template>
<script>
import API from '@/api/system/api_version'
import { uploadFile } from '@/api/api_public'
import _ from 'lodash'
import PagesMixins from '@/mixins/pagesMixins.js'
import { terminalOptions } from '@/config/config'
let addEditForm = {
  targetVer: '',
  startVer: '',
  endVer: '',
  content: '',
  terminal: null,
  link: '',
  enforceFlag: false,
  openPlatform: 'THREE_REPORT_APP'
  // state: true
}
export default {
  data() {
    return {
      listData: [],
      //新增相关数据
      terminalOptions,
      addEditForm: _.cloneDeep(addEditForm),
      addEditDialog: false, //新增编辑弹框是否显示
      addEditTitle: '',
      isAddOrEdit: null, //1新增2编辑
      addEditFormRules: {
        targetVer: [{ required: true, message: '请输入目标版本', trigger: 'blur' }],
        startVer: [{ required: true, message: '请选择开始版本', trigger: 'change' }],
        endVer: [{ required: true, message: '请选择结束版本', trigger: 'change' }],
        content: [{ required: true, message: '请输入升级文案', trigger: 'blur' }],
        terminal: [{ required: true, message: '请选择终端', trigger: 'change' }],
        link: [
          { required: true, message: '请输入链接', trigger: 'blur' },
          { min: 1, max: 255, message: '链接地址太长', trigger: 'blur' }
        ]
      },
      versionOptions: [],
      showUploadFile: false
    }
  },
  mixins: [PagesMixins],
  mounted() {
    this.getListData()
  },
  methods: {
    formatTerminal(row, column, cellValue) {
      return cellValue === 1 ? '安卓' : 'IOS'
    },
    formatEnforceFlag(row, column, cellValue) {
      return cellValue ? '是' : '否'
    },
    formatState(row, column, cellValue) {
      return cellValue ? '启用' : '禁用'
    },
    getListData() {
      let paramsms = {
        page: this.page,
        size: this.size,
        openPlatform: 'THREE_REPORT_APP'
      }
      API.list(paramsms).then(result => {
        if (result && result.code === '000000') {
          this.total = result.data.total
          this.listData = result.data.list
        }
      })
    },
    getVersion() {
      let params = {
        terminal: this.addEditForm.terminal,
        openPlatform: 'THREE_REPORT_APP'
      }
      API.getVersion(params).then(result => {
        if (result && result.code === '000000') {
          this.versionOptions = result.data
        }
      })
    },
    changeTerminal(val) {
      this.getVersion()
      this.showUploadFile = val !== 2 ? true : false
    },
    //新增
    showAddDialog() {
      this.addEditTitle = '新增'
      this.isAddOrEdit = 1
      if (this.$refs['addEditForm'] !== undefined) {
        this.$refs['addEditForm'].resetFields()
      }
      this.showUploadFile = false
      this.addEditForm = _.cloneDeep(addEditForm)
      this.addEditDialog = true
    },
    addSubmit() {
      this.$refs.addEditForm.validate(valid => {
        if (valid) {
          let params = Object.assign({}, this.addEditForm)
          params.enforceFlag = params.enforceFlag === true ? 1 : 0
          params.state = params.state === true ? 1 : 0
          API.add(params).then(result => {
            if (result && result.code === '000000') {
              this.$message.success({ showClose: true, message: result.msg || '新增成功', duration: 2000 })
              this.addEditDialog = false
              this.getListData()
            }
          })
        }
      })
    },
    //编辑
    showEditDialog(row) {
      this.addEditTitle = '编辑'
      this.isAddOrEdit = 2
      if (this.$refs['addEditForm'] !== undefined) {
        this.$refs['addEditForm'].resetFields()
      }
      this.addEditForm = Object.assign({}, row)
      this.addEditForm.enforceFlag = this.addEditForm.enforceFlag === 1 ? true : false
      this.addEditForm.state = this.addEditForm.state === 1 ? true : false
      this.showUploadFile = this.addEditForm.terminal !== 2 ? true : false
      delete this.addEditForm.createTime
      this.getVersion()
      this.addEditDialog = true
    },
    editSubmit() {
      this.$refs.addEditForm.validate(valid => {
        if (valid) {
          let params = Object.assign({}, this.addEditForm)
          params.enforceFlag = params.enforceFlag === true ? 1 : 0
          params.state = params.state === true ? 1 : 0
          API.update(params).then(result => {
            if (result && result.code === '000000') {
              this.$message.success({ showClose: true, message: result.msg || '修改成功', duration: 2000 })
              this.addEditDialog = false
              this.getListData()
            }
          })
        }
      })
    },
    //禁用启用
    updateState(versionId, state) {
      let msg = state === 1 ? '确认启用吗' : '确认禁用吗'
      this.$confirm(msg, '提示', { type: 'warning' }).then(() => {
        let params = {
          versionId,
          state
        }
        API.updateState(params).then(result => {
          if (result && result.code === '000000') {
            this.$message.success({ showClose: true, message: result.msg || '成功', duration: 2000 })
            this.getListData()
          }
        })
      })
    },
    uploadFile(file) {
      let files = file.file
      console.log(file)
      // if (files.type != 'application/vnd.android.package-archive') {
      //   this.$message.error({ showClose: true, message: '文件格式不正确', duration: 2000 })
      //   return
      // }
      let fileType = files.name.substring(files.name.lastIndexOf('.') + 1).toLowerCase()
      if (fileType != 'apk') {
        this.$message.error({ showClose: true, message: '文件格式不正确,请上传apk格式文件', duration: 2000 })
        return
      }
      if (Math.floor(files.size / 1024 / 1024) > 100) {
        this.$message.error({ showClose: true, message: '文件大小不能超过100M', duration: 2000 })
        return
      }
      const formdata = new FormData()
      formdata.append('files', files)
      formdata.append('business', 'version')
      uploadFile(formdata).then(result => {
        if (result && result.code === '000000') {
          this.addEditForm.link = result.data.list[0]
          this.$message.success({ showClose: true, message: result.msg || '上传成功', duration: 2000 })
        }
      })
    }
  }
}
</script>
